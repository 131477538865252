import theme from "./resources/theme";

const styles = {
    appContainer: {
        backgroundColor: theme.palette.primary.main,
        minHeight: "calc(100vh - 80px)",
        padding: "1rem",
    },
    questionnaireContainer: {
        minWidth: "500px",
        borderRadius: "3rem",
        padding: "3rem",
        marginLeft: "2rem",
        marginBottom: "2rem"
    },
    signatureContainer: {
        padding: "3rem",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "3rem",
        marginLeft: "2rem"
    },

}

export default styles;