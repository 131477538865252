import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import UserAvatar from "../UserAvatar/UserAvatar";

export default function ButtonAppBar({ isLogged, setIsLogged, user, setUser }) {
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{height: '80px'}}>
          <img
            src="https://storage.googleapis.com/adsmovil-services-bucket/adsmovil_white.png"
            alt="Adsmovil logo"
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, marginLeft: "1rem" }}
          >
            Signature Generator
          </Typography>
          {
            isLogged &&               
            <UserAvatar isLogged={isLogged} setIsLogged={setIsLogged} user={user} setUser={setUser}/>
          }

        </Toolbar>
      </AppBar>
    </Box>
  );
}
