import React from 'react';

import styles from './Signature.styles';

const Signature = props => {
  const { isSecondaryLogo, isOOH, isNuestraTV, isNMSDC, isDigitalTurbine, isRappiAmplify, haveSkype } = props;

  return (
    <table cellPadding={0} cellSpacing={0} className={'signature'}>
      <tbody>
        <tr>
          <td style={styles.signature.borderSeparator} valign="middle">
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={styles.signature.logoAdsmovil} valign="center">
                    <a href="http://www.adsmovil.com" target="_blank" rel="noreferrer">
                      <img
                        alt="Logo"
                        style={{ border: 0 }}
                        width="200"
                        border="0"
                        src={`https://storage.googleapis.com/adsmovil-services-bucket/${
                          isOOH ? 'logo_ooh_azul.png' : isNuestraTV ? 'NuestraTV.logo.png' : 'firmaNueva-logo.png'
                        }`}
                      />
                    </a>
                  </td>
                </tr>
                {isSecondaryLogo && (
                  <tr>
                    <td style={styles.signature.logosBottomContainer} valign="middle" align="center">
                      {isNMSDC && (
                        <a href="http://www.adsmovil.com" target="_blank" rel="noreferrer">
                          <div
                            style={{
                              display: 'inline-block',
                              background: `url(https://storage.googleapis.com/adsmovil-services-bucket/logo-certified-us.png) center center / contain no-repeat`,
                              height: '84px',
                              width: '80px',
                            }}
                          ></div>
                        </a>
                      )}
                      {isDigitalTurbine && (
                        <a href="http://www.adsmovil.com" target="_blank" rel="noreferrer">
                          <div
                            style={{
                              display: 'inline-block',
                              background: `url(https://storage.googleapis.com/adsmovil-services-bucket/digital_turbine_logo.png) center center / contain no-repeat`,
                              height: '70px',
                              width: '80px',
                            }}
                          ></div>
                        </a>
                      )}
                      {isRappiAmplify && (
                        <a href="http://www.adsmovil.com" target="_blank" rel="noreferrer">
                          <div
                            style={{
                              display: 'inline-block',
                              background: `url(https://storage.googleapis.com/adsmovil-services-bucket/rappi_amplify.png) center center / contain no-repeat`,
                              height: '80px',
                              width: '90px',
                              backgroundRepeat: 'no-repeat',
                            }}
                          ></div>
                        </a>
                      )}
                      <a
                        href={`https://savingtheamazon.adsmovil.com/${isNMSDC ? 'eng' : ''}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          height: 'auto',
                          marginLeft: isSecondaryLogo ? '5px' : '0',
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            backgroundImage: `url(https://storage.googleapis.com/adsmovil-services-bucket/amazon_logo.png)`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            height: '70px',
                            width: '90px',
                            backgroundRepeat: 'no-repeat',
                          }}
                        ></div>
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
          {/* RIGHT SIDE */}

          <td
            style={{
              fontFamily: 'Myriad Pro, sans-serif',
              paddingLeft: '10px',
              verticalAlign: 'middle',
            }}
            valign="top"
          >
            <div style={{ marginRight: '0', marginLeft: 'auto', color: '#1F2653' }} cellPadding="0" cellSpacing="0">
              <div>
                <div
                  style={{
                    height: 'auto',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}
                  valign="top"
                  align="left"
                >
                  <p style={styles.signature.textTitle}>{props.fullName}</p>
                </div>
                <div
                  style={{
                    height: 'auto',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}
                  valign="top"
                  align="left"
                >
                  <p style={styles.signature.text}>{props.position}</p>
                </div>
                <div
                  style={{
                    verticalAlign: 'top',
                    textAlign: 'left',
                    height: '12px',
                  }}
                  valign="top"
                  align="left"
                >
                  <span style={styles.signature.textLink}>
                    {props.phone} |{' '}
                    <a href={`mailto:${props.email}}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {props.email}
                    </a>
                    <span>
                      <br />
                    </span>
                  </span>
                </div>
                <div style={{ height: '12px' }}>
                  <div
                    style={{
                      verticalAlign: 'top',
                      textAlign: 'left',
                    }}
                    valign="top"
                    align="left"
                  >
                    <span style={styles.signature.textLink}>
                      <a
                        href="http://www.adsmovil.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        www.adsmovil.com
                      </a>{' '}
                      {haveSkype && (
                        <span>
                          | Skype: <u>{props.skype}</u>
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      verticalAlign: 'top',
                      textAlign: 'left',
                    }}
                    valign="top"
                    align="left"
                  >
                    <span style={styles.signature.textLink}>{props.location}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.signature.logosSocialMediaContainer} valign="middle" align="left">
              <span>
                {' '}
                <a
                  href="http://facebook.com/adsmovil"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    border="0"
                    width="23"
                    alt="Facebook icon"
                    style={{
                      ...styles.signature.logosSocialMedia,
                      marginLeft: 0,
                    }}
                    src="https://storage.googleapis.com/adsmovil-services-bucket/ico-fa.png"
                  />
                </a>
              </span>
              <span>
                {' '}
                <a
                  href="https://co.linkedin.com/company/adsmovil"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    border="0"
                    width="23"
                    alt="LinkedIn icon"
                    style={styles.signature.logosSocialMedia}
                    src="https://storage.googleapis.com/adsmovil-services-bucket/ico-lin.png"
                  />
                </a>
              </span>
              <span>
                {' '}
                <a
                  href="http://twitter.com/adsmovil"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    border="0"
                    width="23"
                    alt="Twitter icon"
                    style={styles.signature.logosSocialMedia}
                    src="https://storage.googleapis.com/adsmovil-services-bucket/ico-tw.png"
                  />
                </a>
              </span>
              <span>
                {' '}
                <a
                  href="https://www.instagram.com/adsmovil"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    border="0"
                    width="23"
                    alt="Twitter icon"
                    style={styles.signature.logosSocialMedia}
                    src="https://storage.googleapis.com/adsmovil-services-bucket/ico-ins.png"
                  />
                </a>
              </span>
              <span>
                {' '}
                <a
                  href="https://www.youtube.com/user/adsmovilnetwork"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    border="0"
                    width="23"
                    alt="Youtube icon"
                    style={styles.signature.logosSocialMedia}
                    src="https://storage.googleapis.com/adsmovil-services-bucket/ico-youtube.png"
                  />
                </a>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Signature;
