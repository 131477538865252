import theme from "../../resources/theme";

const styles = {
    questionnaireTitle: {
        color: theme.palette.secondary.main,
        marginBottom: "2rem",
    },
    logosContainer: {
        margin: "0.5rem 1rem",
        padding: "0.25rem 1rem",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "3rem",
        minWidth: "150px",
        minHeight: "60px",
        transition: "all 0.5s ease-in-out",

        "&:hover": {
            backgroundColor: theme.palette.common.white,
            borderRadius: '0',
            scale: 1.1,
        }
    },
}

export default styles;