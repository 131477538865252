import theme from "../../resources/theme";

const styles = {
  signature: {
    textTitle: {
      fontFamily: theme.fonts.primary,
      fontSize: "10pt",
      fontWeight: "700",
      color: theme.palette.primary.main,
      margin: '0'
    },
    text: {
      fontFamily: theme.fonts.primary,
      fontSize: "8pt",
      fontWeight: "600",
      color: theme.palette.primary.main,
      textDecoration: "none",
      margin: '0'
    },
    textLink: {
      fontFamily: theme.fonts.primary,
      fontSize: "7pt",
      fontWeight: "500",
      color: theme.palette.primary.main,
      textDecoration: "none",
      margin: '0'
    },
    borderSeparator: {
      fontSize: "1pt",
      borderRight: `0.18rem solid ${theme.palette.secondary.main} `,
      textAlign: "center",
      paddingRight: "10px",
      verticalAlign: "middle",
      fontFamily: "Myriad Pro, Helvetica, Arial, sans-serif",
      fontWeight: "700",
      fontStyle: "normal",
    },
    logoAdsmovil: {
      height: "60px",
      width: "200px",
      verticalAlign: "center",
    },
    logosBottomContainer: {
      height: "70px",
      width: "80px",
      verticalAlign: "center",    
      textAlign: "center",
    },
    logosBottomLeft: {
      border: 0,
      marginRight: "10px",
    },
    logosSocialMediaContainer: {
      height: "auto",
      paddingTop: "8px",
    },
    logosSocialMedia: {
      border: "0",
      height: "24px",
      width: "24px",
    },
  },
};

export default styles;
