import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { googleLogout } from "@react-oauth/google";
import UserOptions from "./UserOptions/UserOptions";

export default function UserAvatar({isLogged, setIsLogged, user, setUser}) {

    const [anchorEl, setAnchorEl] = useState(null);

    const opensHandler = (open) => {
        
      handleClose()
    }

    const logoutHandler = () => {
        handleClose()
        googleLogout()
        setIsLogged(false)
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return(
        <Box sx={{display: "flex", alignItems: "center"}}>
            {isLogged ? 
                <Button onClick={handleClick}>
                    <Avatar
                    alt={ `${user.name}`}
                    src={user.picture}
                    sx={{ border: 1, borderColor: "accent.main", boxShadow: "0 0 7px 1px #32bdc1b3", color: "navbar.main", backgroundColor: "accent.transparent", fontSize: "1.1rem" }}
                    />
                </Button>
            :
            <Button variant="text" color="navbar" onClick={() => opensHandler("login")}>
                Login
            </Button>
            }
            <UserOptions
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{width: "220px", p: 2, '& .MuiButtonBase-root': {width: 1, justifyContent: "flex-start", textTransform: "none"}}}
            >
                <Typography variant="body2">{`${user.name}`}</Typography>
                <Divider sx={{my: 1}}/>
                {/* <Button onClick={() => opensHandler("config")}>Panel Personal</Button> */}
                <Button onClick={logoutHandler}>Cerrar sesión</Button>
            </UserOptions>
        </Box>
    )
}
