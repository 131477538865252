import React from "react";

import { Grid, TextField } from "@mui/material";

import styles from "./Form.styles";

const FormItem = ({ formInput, handleChangeInput, state }) => {

    return (
        <Grid item sx={styles.formItem}>
          <TextField
            variant="outlined"
            label={formInput.label}
            onChange={handleChangeInput}
            name={formInput.name}
            color="primary"            
            fullWidth
            value={state[formInput.name]}
            placeholder={state[formInput.name] ? "" : state.placeholders[formInput.name]}
          />
        </Grid>
    )
}

export default FormItem;