import theme from "../../resources/theme";

const styles = {
  formContainer: {
    padding: "2rem",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "2rem",
  },
  formTitle: {
    color: theme.palette.primary.main,
    marginBottom: "2rem",
  },
  formItem: {
    marginBottom: "1rem",
    minWidth: "20rem",
  },
};

export default styles;
