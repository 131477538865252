import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { Grid, Typography } from "@mui/material";

import styles from "./Form.styles";

import FormItem from "./FormItem";

const FORMINPUTS = [
  {
    label: "Nombre",
    name: "fullName",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Teléfono",
    name: "phone",
  },
  {
    label: "Posición",
    name: "position",
  },
  {
    label: "Skype",
    name: "skype",
  },
  {
    label: "Ubicación",
    name: "location",
  },
];

const Form = ({ state, setState, handleChangeSkype, user }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    name === "skype" && handleChangeSkype(e);
  };

  useEffect(() => {
    setState({
      ...state,
      fullName: user.name,
      email: user.email,
    });
  }, [user, setState, state]);

  return (
    <Grid container item direction="column" xs={4} sx={{...styles.formContainer, marginLeft: isTablet ? '0' : 'inherit'}}>
      <Grid item>
        <Typography variant="h3" sx={styles.formTitle}>Formulario</Typography>
      </Grid>
      <Grid item>
        {FORMINPUTS.map((formInput) => (
          <FormItem
            key={formInput.name}
            formInput={formInput}
            handleChangeInput={handleChangeInput}
            state={state}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default Form;
