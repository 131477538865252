import React from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { Box, Avatar, CssBaseline, Paper, Grid, Typography } from '@mui/material';
import Image from 'mui-image';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

import logInImage from '../../assets/img_login.png';

const UnloggedView = ({ setActionModal, setIsLogged, setOpenModal, setUser }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      container
      justifyContent={isTablet ? 'center' : 'inherit'}
      sx={{ height: 'calc(100vh - 80px)', padding: '1rem' }}
    >
      <CssBaseline />
      <Grid
        item
        container
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: theme.palette.primary.main,
          display: { xs: 'none', sm: 'none', md: 'flex' },
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={{ width: '90%', height: '90%' }}>
          <Image
            src={logInImage}
            alt="log in"
            width="100%"
            height="100%"
            duration={1500}
            easing="cubic-bezier(0.7, 0, 0.6, 1)"
            shiftDuration={900}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={11}
        sm={7}
        md={4}
        component={Paper}
        elevation={6}
        sx={{
          borderRadius: '2rem',
          margin: '0 2rem',
          height: '100%',
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            sx={{
              mt: 1,
              minHeight: '250px',
              minWidth: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GoogleLogin
              size="large"
              onSuccess={credentialResponse => {
                setIsLogged(true);
                const decoded_credentials = jwt_decode(credentialResponse.credential);
                const hosted_domain = decoded_credentials.hd;
                if (
                  hosted_domain === 'nuestra.tv' ||
                  hosted_domain === 'adsmovil.com' ||
                  hosted_domain === 'adsmovilooh.com' ||
                  hosted_domain === 'adsmovil.com.co'
                ) {
                  setIsLogged(true);
                  setUser({
                    name: decoded_credentials.name,
                    email: decoded_credentials.email,
                    picture: decoded_credentials.picture,
                  });
                } else {
                  setActionModal('noAdsmovil');
                  googleLogout();
                  setIsLogged(false);
                  setOpenModal(true);
                }
              }}
              onError={() => {
                setActionModal('noUser');
                googleLogout();
                setIsLogged(false);
                setOpenModal(true);
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UnloggedView;
