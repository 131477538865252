import React from 'react';
import { Grid, Typography, IconButton, Switch } from '@mui/material';

import styles from './LogosQuestionnaire.styles';

const LogosQuestionnaire = ({
  isSecondaryLogo,
  handleClickOOH,
  handleClickNuestraTV,
  handleClickSecondaryLogo,
  handleClickSavingAmazon,
  handleClickNMSDC,
  handleClickDigitalTurbine,
  handleClickRappiAmplify,
}) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="h4" sx={styles.questionnaireTitle}>
          ¿Qué logo principal desea?
        </Typography>

        <Grid item container direction="row" sx={{ marginBottom: '2rem' }}>
          <Grid item>
            <IconButton
              sx={styles.logosContainer}
              onClick={() => {
                handleClickOOH(false);
                handleClickNuestraTV(false);
              }}
            >
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/firmaNueva-logo.png"
                width="150px"
                alt="Logo Adsmovil OOH"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton sx={styles.logosContainer} onClick={() => handleClickOOH(true)}>
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/logo_ooh_azul.png"
                width="150px"
                alt="Logo Adsmovil OOH"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton sx={styles.logosContainer} onClick={() => handleClickNuestraTV(true)}>
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/NuestraTV.logo.png"
                width="150px"
                alt="Logo Adsmovil OOH"
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container>
          <Typography variant="h4" sx={styles.questionnaireTitle}>
            ¿Desea algún logo secundario?
          </Typography>
          <Switch
            color="secondary"
            checked={isSecondaryLogo}
            onChange={handleClickSecondaryLogo}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item container direction="row">
          <Grid item>
            <IconButton
              sx={{
                ...styles.logosContainer,
                filter: isSecondaryLogo ? 'grayscale(0%)' : 'grayscale(100%)',
              }}
              onClick={handleClickSavingAmazon}
              disabled={!isSecondaryLogo}
            >
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/amazon_logo.png"
                width="100px"
                alt="Saving the Amazon logo"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                ...styles.logosContainer,
                filter: isSecondaryLogo ? 'grayscale(0%)' : 'grayscale(100%)',
              }}
              onClick={handleClickNMSDC}
              disabled={!isSecondaryLogo}
            >
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/logo-certified-us.png"
                width="50px"
                alt="Certified logo"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                ...styles.logosContainer,
                filter: isSecondaryLogo ? 'grayscale(0%)' : 'grayscale(100%)',
              }}
              onClick={handleClickDigitalTurbine}
              disabled={!isSecondaryLogo}
            >
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/digital_turbine_logo.png"
                width="100px"
                alt="Digital Turbine logo"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                ...styles.logosContainer,
                filter: isSecondaryLogo ? 'grayscale(0%)' : 'grayscale(100%)',
              }}
              onClick={handleClickRappiAmplify}
              disabled={!isSecondaryLogo}
            >
              <img
                src="https://storage.googleapis.com/adsmovil-services-bucket/rappi_amplify.png"
                width="100px"
                alt="Digital Turbine logo"
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogosQuestionnaire;
