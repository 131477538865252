import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1f2653'
        },
        accent: {
            main: "#32bdc1",
            transparent: "#32bdc1b3",
        },
        secondary: {
            main: '#73BFC2'
        },
        background: {
            default: '#fff',
            paper: '#FBF9F6'
        },
        error: {
            main: '#ff0000'
        }
    },
    fonts: {
        primary: {
            fontFamily: 'Roboto'
        }
    }
});

export default theme;