import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { useTheme } from "@emotion/react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function NotificatorModal({
  openModal,
  setOpenModal,
  actionModal,
}) {
  const theme = useTheme();
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {actionModal === "copy" ? (
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              ¡¡FIRMA COPIADA EN PORTAPALES!!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Crear una nueva firma en la sección de firmas de gmail y copie el
              texto de la firma.
            </Typography>
            <Typography id="modal-modal-description-2" sx={{ mt: 2 }}>
              Si necesita asistencia, consulte con la documentación de gmail
              para establecer una nueva firma.
            </Typography>
            <Link
              href="https://support.google.com/mail/answer/8395?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/mail/answer/8395?hl=es
            </Link>
          </Box>
        )
        : actionModal === "noAdsmovil" ? (
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color={theme.palette.error.main}>
              ¡¡NO ES DE ADSMOVIL!!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              No es un usuario de adsmovil, por favor inicie sesión con su
              cuenta de adsmovil.
            </Typography>
          </Box>
        )
        : actionModal === "noUser" && (
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color={theme.palette.error.main}>
              ¡¡NO SE HA INICIADO SESIÓN!!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              A ocurrido un error en el inicio de sesión, por favor intente nuevamente.
            </Typography>
          </Box>
        )}
      </Modal>
    </div>
  );
}
