const { Popover, Box } = require("@mui/material")

const UserOptions = ({id, open, anchorEl, onClose, children, sx}) => {

    return(
    <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    >
        <Box sx={sx}>

        {children}
        </Box>
    </Popover>
    )
}

export default UserOptions
